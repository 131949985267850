
@import "../../bootstrap/variables.less";

@body-bg:					#000;

@text-color:				#fff;
@link-color:				#fff;
@link-hover-color:			#fff;
@link-hover-decoration:		underline;

@font-family-base:			sans-serif;
@font-size-base:			14px;
@line-height-base:			1;

@font-size-h1:				24px;
@headings-small-color:		#fff;

@thumb-width:				242px;
@thumb-height:				182px;
@thumb-margin-top:			2px;
@thumb-margin-right:		3px;
@thumb-margin-bottom:		5px;
@thumb-margin-left:			3px;
@thumb-border-width:		1px;
@thumb-border:				@thumb-border-width solid #323630;
@thumb-hover-border:		@thumb-border-width solid #ff0000;

@zindex-popup:				100;
@tooltip-arrow-width:		5px;
@tooltip-bg:				#fff;
@popup-text-color:			#000;
@popup-font-weight:			bold;

@social-links-margin:		5px 0;

@sites-list-font-size:				12px;
@sites-list-color:					#fff;
@sites-list-title-margin-bottom:	5px;
@sites-list-title-size:				20px;
@sites-list-title-weight:			bold;
@sites-list-title-decoration:		underline;

@parent-alert-padding:		10px;
@parent-alert-margin:		10px auto;
@parent-alert-border:		1px solid #fff;
@parent-alert-font-size:	11px;
@parent-alert-color:		#fff;
@parent-alert-bg:			transparent;

@import "common/bootstrap.less";
@import "../../common/utils.less";
@import "../../common/flags-small.less";
@import "../../common/flags.less";
@import "common/sites_list.less";
@import "common/parent_alert.less";
@import "common/modules.less";
@import "common/popup.less";
@import "common/mozaique.less";

h1 {
	text-align: center;
	margin: 0 auto 15px;
	.title {
		color: #fff;
		font-weight: bold;
	}
}
#language-switcher {
	float: right;
	margin: 0 10px;
}
.thumb-block {
	p {
		font-size: 12px;
		line-height: 15px;
		a {
			font-weight: bold;
			color: #fff;
			text-decoration: underline;
		}
	}
}